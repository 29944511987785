import { FC, memo } from 'react';
import { Helmet } from 'react-helmet';

import platformConfig from '@/platforms/configs';
import ogOdg from '@/public/static/ic-og-odg.jpg';
import { getCurrentLanguage } from '@/utils/nextRouter';

const platformMeta = platformConfig.meta;

const HelmetComponent: FC = () => (
  <Helmet
    title={platformMeta.title}
    meta={[
      { name: 'description', content: platformMeta.description },
      { name: 'keywords', content: platformMeta.keywords },
      {
        name: 'viewport',
        content:
          'width=device-width, height=device-height, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no',
      },
      { property: 'og:title', content: platformMeta.ogTitle || platformMeta.title },
      { property: 'og:url', content: platformMeta.ogUrl },
      { property: 'og:image', content: platformMeta.ogImg || ogOdg },
      { property: 'og:type', content: platformMeta.ogType },
      { property: 'og:site_name', content: platformMeta.ogSiteName },
      { property: 'og:description', content: platformMeta.ogDescription || platformMeta.description },
      { property: 'og:locale', content: platformMeta.ogLocale || getCurrentLanguage() },
    ]}
    link={[{ rel: 'canonical', href: platformMeta.canonical || platformMeta.ogUrl }]}
  />
);

export default memo(HelmetComponent);
