import { NextPage } from 'next';

import Helmet from '@/components/Helmet';
import Namespace from '@/constants/namespace';
import Urls from '@/constants/urls';
import { I18NextPageProps } from '@/interfaces/page';
import { createServerSidePage } from '@/utils/nextPage';
import Router from '@/utils/nextRouter';

interface Props extends I18NextPageProps {}

const IXTIndex: NextPage<Props> = () => {
  Router.replaceRoute(Urls.product.Workbench);
  return (
    <>
      <Helmet />
    </>
  );
};

export const getServerSideProps = createServerSidePage({
  namespacesRequired: [Namespace.ODP_SYSADMIN, Namespace.ODP_GENERAL],
});

export default IXTIndex;
